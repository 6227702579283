import PropTypes from 'prop-types';
import React from 'react';

import Banner from '@ui/Banner';

const InactiveBanner = ({ isActive }) => {
  return isActive ? null : (
    <Banner data-testid="inactive-banner" icon="icon-alert-triangle">
      This site is only viewable by admins.
      <a href={'/dash?to=plans'}>Launch it to make it public.</a>
    </Banner>
  );
};

InactiveBanner.propTypes = {
  isActive: PropTypes.bool,
};

export default InactiveBanner;
